.noResults {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noResults .noResultsContent {
  text-align: center;
}
